import React from "react"
import Layout from '../components/global/layout.js'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { Helmet } from "react-helmet"
import LandingSection from "../components/global/landingsection.js"
import ContentSection from "../components/global/contentsection.js"
import jobsStyles from "../style/jobs.module.scss"
import Friendly from "../img/jobs-friendly.svg"
import Connection from "../img/jobs-connection.svg"
import Location from "../img/jobs-location.svg"
import JobsCard from "../components/jobs/jobs.card.js"


const JobsPage = () => {

    const intl = useIntl();
    const alt1 =  <p>{intl.formatMessage({id: "Jobs.expect.1.img"})}</p>
    const altText1 =  alt1.props.children
    const alt2 =  <p>{intl.formatMessage({id: "Jobs.expect.2.img"})}</p>
    const altText2 =  alt2.props.children
    const alt3 =  <p>{intl.formatMessage({id: "Jobs.expect.3.img"})}</p>
    const altText3 =  alt3.props.children

    return (

        <Layout>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <title> Spherical Elephant | Jobs </title>
                <meta name="description" content="Spherical Elephant GmbH | Werde Teil unserer Elephantenherde!"></meta>
            </Helmet>

            <LandingSection
                h1={<FormattedMessage id="Jobs.h1" />}
                title={<FormattedMessage id="Jobs.title" />}
                subtitle={<FormattedMessage id="Jobs.subtitle" />}
            />

            <ContentSection>
                <div className={jobsStyles.jobsWrapper}>
                    <div className={jobsStyles.contentWrapper}>
                        <h2><FormattedMessage id = "Jobs.positions.h" /></h2>
                        <JobsCard title = { <FormattedMessage id = "Jobs.fullstack.position.title" />} url = "../jobs_fullstack"/>
                    </div>

                    <div className={jobsStyles.expect}>
                        <h2><FormattedMessage id = "Jobs.expect.h" /></h2>
                        <div className={jobsStyles.expectFlexContainer}>
                            <div>
                                <div className = {jobsStyles.imageContainer}>
                                <img style = {{width: "4rem"}} className = {jobsStyles.expectIcon} src={Friendly} alt= {altText1} />
                                </div>
                                <h3><FormattedMessage id = "Jobs.expect.1.h" /></h3>
                                <p><FormattedMessage id = "Jobs.expect.1.p" /></p>
                            </div>
                            <div>
                                <div className = {jobsStyles.imageContainer}>
                                <img style = {{width: "5.5rem"}} className = {jobsStyles.expectIcon} src={Connection} alt= {altText2} />
                                </div>
                                <h3><FormattedMessage id = "Jobs.expect.2.h" /></h3>
                                <p><FormattedMessage id = "Jobs.expect.2.p" /></p>
                            </div>
                            <div>
                                <div className = {jobsStyles.imageContainer}>
                                <img  style = {{width: "2.7rem"}} className = {jobsStyles.expectIcon} src={Location} alt= {altText3} />
                                </div>
                                <h3><FormattedMessage id = "Jobs.expect.3.h" /></h3>
                                <p><FormattedMessage id = "Jobs.expect.3.p" /></p>
                            </div>
                        </div>

                    </div>
                </div>

            </ContentSection>

        </Layout>
    )
}

export default JobsPage
